import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import AutoSuggest from 'react-autosuggest';
import { Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { searchPostcodes } from '../../state/user/user.service';
import { selectPostcode$, setCheckoutPostcode } from '../../state/checkout/checkout.service';
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import * as Styled from './styles';
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import CloseIcon from './../ui/icons/close'

const PostCodeModal = ({ type, suggestionSelectedCustomCallback, addedCallback }) => {
  const { language } = React.useContext(I18nextContext);
  const locale = language;
  const gql = useStaticQuery(graphql`
    query {
      strapiPostcodeModal_en: strapiPostcodeModal(locale: {eq: "en"}) {
        please_enter_postcode_text
        change_postcode
        add_postcode
        postcode_field_label
      }
      strapiPostcodeModal_zh: strapiPostcodeModal(locale: {eq: "zh"}) {
        please_enter_postcode_text
        change_postcode
        add_postcode
        postcode_field_label
      }
    }
  `);
  const translations = gql[`strapiPostcodeModal_${locale}`];
  const { navigate } = useI18next();
  const [unMount$] = useState(new Subject());
  const [searchValue$] = useState(new Subject());
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [postCode, setPostcode] = useState(null);

  useEffect(() => {
    searchValue$
      .pipe(
        takeUntil(unMount$),
        debounceTime(300),
        switchMap((value) => searchPostcodes(value)),
        catchError(() => {
          alert('Postcode Search Failed');
        })
      )
      .subscribe((results) => {
        setSuggestions(results);
      });

    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const enterPostcode = () => {
    setIsOpen(!isOpen);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    searchValue$.next(value);
  };

  const onSuggestionSelected = async (e, { suggestionValue }) => {
    setIsOpen(false);
    if (!!suggestionValue?.serviced) {
      setCheckoutPostcode(suggestionValue);
    } else {
      await navigate('/unserviced/?pdata=' + JSON.stringify(suggestionValue));
    }

    if (!!suggestionSelectedCustomCallback) {
      suggestionSelectedCustomCallback();
    }
  };

  const onSuggestionSelectedBottomSheet = async (e, { suggestionValue }) => {
    if (addedCallback) {
      addedCallback(false);
    }

    if (!!suggestionValue?.serviced) {
      setCheckoutPostcode(suggestionValue);
    } else {
      await navigate('/unserviced/?pdata=' + JSON.stringify(suggestionValue));
    }

    if (!!suggestionSelectedCustomCallback) {
      suggestionSelectedCustomCallback();
    }
  };

  const getSuggestionValue = (suggestion) => ({
    id: suggestion.id,
    name: suggestion.name,
    serviced: suggestion.serviced,
    delivery_fee: suggestion.delivery_fee,
    est_del_time: suggestion.est_del_time,
  });

  const onChange = (_, { newValue }) => {
    setSearchValue(newValue.name ? newValue.name : newValue);
  };

  return (
    <>
      {type === '1' && (
        <div className="mb-40">

          <Styled.Desktop>
            <Styled.Wrap>
              <p className="potTitle thin">
                {translations.please_enter_postcode_text}
              </p>
              <p className="postLabel">{translations.postcode_field_label}</p>
            </Styled.Wrap>
          </Styled.Desktop>

          <Styled.Mobile>
            <p className="text-base text-brown-semidark text-center mb-2 font-bold">
              {translations.please_enter_postcode_text}
            </p>
          </Styled.Mobile>

          <AutoSuggest
            suggestions={suggestions}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionSelected={onSuggestionSelectedBottomSheet}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
            inputProps={{
              value: searchValue,
              name: 'postcode',
              className:
                'w-full border-1 border-gray-200 h-8 px-4 py-5 text-xs focus:outline-none',
              onChange: onChange,
            }}
            highlightFirstSuggestion={true}
          />
        </div>
      )}

      {type!== '1' && (
        <>
          <a onClick={() => enterPostcode()} className="text-blue-600">
            {' '}
            {!!postCode?.id && <>[ {translations.change_postcode} ]</>}
            {!postCode?.id && <>[ {translations.add_postcode} ]</>}
          </a>

          <Modal
            isOpen={isOpen}
            onRequestClose={enterPostcode}
            contentLabel="My dialog"
            id="postCodeModal"
          >
            <Styled.Btn>
              <button onClick={enterPostcode} className="w-full">
                <CloseIcon />
              </button>
            </Styled.Btn>

            <Styled.Wrap>
            <p className="potTitle thin">
              {translations.please_enter_postcode_text}
            </p>
            <p className="postLabel">{translations.postcode_field_label}</p>
            </Styled.Wrap>
            <AutoSuggest
              suggestions={suggestions}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
              inputProps={{
                value: searchValue,
                name: 'postcode',
                className:
                  'w-full border-1 border-gray-200 h-8 px-4 py-5 text-xs focus:outline-none',
                onChange: onChange,
              }}
              highlightFirstSuggestion={true}
            />
          </Modal>
        </>
      )}
    </>
  );
};

PostCodeModal.propTypes = {
  locale: PropTypes.string,
  addedCallback: PropTypes.func,
};

PostCodeModal.defaultProps = {
  addedCallback: () => {},
};

export default PostCodeModal;
