import styled from 'styled-components';
import tw from 'twin.macro';

export const Lang = styled.div`
  p.title{
    margin: 24px 0;
    font-size: 12px;
    line-height: 100%;
    align-items: center;
    text-align: center;
    color: #383C3F;
  }
  hr{
    margin-top: 24px;
  }
`;

export const Wrap = styled.div`
  ${tw`w-full`}
  padding: 30px 84px 0;
  h3{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin: 24px 0 4px;
  }
  p{
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #383C3F;
    margin-bottom: 24px;
  }
  button{
    background: #0D090E;
    padding: 0px 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    width: 100%;
    margin: 0 0 8px;
  }
  .white{
    background: #ffffff;
    border: 1px solid #000000;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #0D090E;
  }
  img{
    ${tw`w-full`};
  }
  @media (max-width: 768px) {
    ${tw`p-0`};
  }
`;
