import styled from "styled-components";
import tw from 'twin.macro';

export const WrapContent = styled.div`
  ${tw`text-left`};
  h2{
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 10px;
  };
  button:not(.react-datepicker__navigation){
    ${tw`text-base py-2 px-6 text-white bg-black border border-black hover:text-black hover:bg-white`};
  }
  input.disabled{
    background: #F5F5F5;
  }
`;

export const Hr = styled.div`
  height: 1px;
  border-top: 1px solid #D6D6D6;
  margin: 16px 0 24px;
`;

export const Grid = styled.div`
  ${tw`grid grid-cols-3 gap-4 pb-4`};
  span{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #292C2F;
    ${tw`font-sans`};
  }
  input{
    ${tw`text-xssemi px-3 text-brown-darker font-normal`};
  }
`;

export const Colspan1 = styled.div`
  ${tw`col-span-3 sm:col-span-1`};
`;

export const Colspan2 = styled.div`
  ${tw`col-span-3 sm:col-span-2`};
`;

export const Colspan3 = styled.div`
  ${tw`col-span-3`};
  h2{
    ${tw`text-sm5`};
  }
`;

export const Notes = styled.div`
  background: #FFFAF5;
  ${tw`text-sm w-full border border-yellowu-basic rounded p-3 mb-4`};
  label{
    color: #425567;
    letter-spacing: 0.4px;
    ${tw`text-xssemi`}
  };
  p{
    color: #656B70;
    letter-spacing: 0.8px;
    ${tw`text-xs font-normal`}
  };
`;
