import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import Map from './../../../ui/icons/map';
import ArrowDown from './../../../ui/icons/arrowDown';
import PostCodes from './../../../PostcodeModal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectPostcode$ } from "../../../../state/checkout/checkout.service";
import { I18nextContext } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";

const DeliverTo = () => {
  const { language } = React.useContext(I18nextContext);
  const locale = language;
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        deliver_to
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        deliver_to
      }
    }
  `);

  const navTranslations = gql[`strapiNavigationMenu_${locale}`];

  const [unMount$] = useState(new Subject());
  const [openPostCode, setOpenPostCode] = useState(false);
  const [postCode, setPostcode] = useState(null);

  useEffect(() => {
    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  function onDismiss() {
    setOpenPostCode(false);
  }

  return (
    <>
    <p>{navTranslations.deliver_to}</p>
    <div className='flex-div'>
      <div className="m-auto" onClick={() => setOpenPostCode(true)}>
        <Map/>
        <span className='bottomSheetPostCode' style={{color: '#6e6b6b'}}>{!postCode ? 'Unknown' : postCode.name}</span>
        <button >
          <ArrowDown/>
        </button>
      </div>
    </div>
    <BottomSheet
      open={openPostCode}
      scrollLocking={false}
      onDismiss={onDismiss}
      snapPoints={({ minHeight }) => minHeight}
    >
      <div className="w-full py-4 px-4 z-20 pb-28">
        <PostCodes type="1" addedCallback={() => {
            setOpenPostCode(false);
          }}/>
      </div>
    </BottomSheet>
    </>
  );
};

DeliverTo.propTypes = {
  locale: PropTypes.string,
};

DeliverTo.defaultProps = {
  locale: 'en',
};

export default DeliverTo;
