import React from 'react';
import PropTypes from 'prop-types';

const Danger = ({ message, extraClassNames }) => (
  <div
    className={`bg-redu-light border border-red-300 text-gray-600 px-4 py-2 rounded relative mt-2 text-sm ${extraClassNames}`}
    role="alert"
  >
    <span className="block sm:inline">{message}</span>
  </div>
);

Danger.propTypes = {
  message: PropTypes.string,
  extraClassNames: PropTypes.string,
};

export default Danger;
