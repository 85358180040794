import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`flex w-full mx-auto cursor-pointer gap-1`};
  button{
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    ${tw`w-6/12 flex text-left border`};
    img{
      ${tw`w-auto mr-2`};
    }
    span{
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.08em;
      color: #9A9292;
      margin:4px 0;
    }
    p{
      font-size: 12px;
      line-height: 15px;
      color: #383C3F;
    }
  }
`;
