import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import { AnimatePresence, motion } from 'framer-motion';
import { akitaDevtools } from '@datorama/akita';
import { getCustomerProfile, selectUser$ } from '../../state/user/user.service';
import { getAnonCart } from '../../state/cart/cart.service';

import Header from 'components/Header';

import 'assets/styles/global.css';
import GlobalStyles from 'assets/styles/globalStyles';
import * as Styled from './styles';
import { getLocalStorageItem, isRunTime, removeLocalStorageItem, setLocalStorageItem } from '../../state/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { startOfDay, isYesterday, parseISO } from 'date-fns';
import { useI18next } from "gatsby-plugin-react-i18next";
import 'react-toastify/dist/ReactToastify.css';

akitaDevtools({
  name: 'ustore',
  sortAlphabetically: true,
});

const loginUser = async (user) => {
  const accessToken = getLocalStorageItem('uStoreAccessToken');
  const anonCartId = getLocalStorageItem('uStoreAnonCartId');

  if (accessToken && !user.id) {
    await getCustomerProfile();
  } else if (!user.id && !anonCartId) {
    await getAnonCart();
  } else if (!user.id && anonCartId) {
    await getAnonCart(+anonCartId);
  }
};

const Layout = ({ children, page, locale }) => {
  const { navigate } = useI18next();
  const LSTime = getLocalStorageItem('uStoreLSTime')

  if (LSTime && isYesterday(parseISO(LSTime))) {
    removeLocalStorageItem('uStoreAccessToken');
    removeLocalStorageItem('uStoreCheckoutAddressId');
    removeLocalStorageItem('uStoreCheckoutCardId');
    removeLocalStorageItem('uStoreAnonCartId');
    removeLocalStorageItem('uStoreAccessRedirectBackTo');
    removeLocalStorageItem('uStoreDeliveryAcceptance');
    removeLocalStorageItem('uStoreDeliveryDueDate');
    removeLocalStorageItem('uStoreDeliveryInstructions');
    removeLocalStorageItem('uStorePromptPwaInstall');
    setLocalStorageItem('uStoreLSTime', startOfDay(new Date()))
    navigate('/logout')
  } else if (!LSTime) {
    if (isRunTime()) {
      setLocalStorageItem('uStoreLSTime', startOfDay(new Date()))
      navigate('/shop')
    }
  }

  const [unMount$] = useState(new Subject());

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe(async (user) => {
      if (!user.id) {
        await loginUser(user);
      }
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <AnimatePresence exitBeforeEnter>
        <Styled.Layout id={page} className={page === 'shop' ? 'bg-grayu-semimed' : ''}>
          <Header siteTitle={data.site.siteMetadata.title} page={page} locale={locale}/>
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2 }}
          >
            {children}
          </motion.div>
          <ToastContainer />
          <Styled.Footer>
            {locale === 'en' ? <p>NO ALCOHOL CAN BE SOLD OR SUPPLIED TO ANYONE UNDER 18. IT’S AGAINST THE LAW.</p> : <p>不得向 18 岁以下的任何人出售或供应酒精饮料。这是违法的。</p>}
            {locale === 'en' ? <p>License Number: LIQP770017656</p> : <p>经营许可证号：LIQP770017656</p>}
          </Styled.Footer>
        </Styled.Layout>
      </AnimatePresence>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  locale: PropTypes.string,
};

Layout.defaultProps = {
  page: '',
  locale: 'en',
};

export default Layout;
