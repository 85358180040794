import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import Modal from 'react-modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as Styled from './styles';
import Visa from 'assets/images/visa.png';
import DefaultCC from 'assets/images/default-cc.png';
import MC from 'assets/images/mastercard.png';
import { selectUserPaymentCards$ } from '../../../state/user/user.service';

import {
  createPaymentCard,
  deletePaymentCard,
  getPaymentCards,
  setDefaultPaymentCard,
} from '../../../state/user/payment-card.service';
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Danger from "../../ui/Alert/Danger";
import { setMonth, setYear, startOfMonth } from "date-fns";

const Transactions = ({ selectForPayment, locale, setCardCallback }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiAccountModal_en: strapiAccountModal(locale: {eq: "en"}) {
        your_wallet_add_new_card_text
        your_wallet_default_card_text
        your_wallet_delete_card_text
        your_wallet_heading
        your_wallet_make_default_card_text
        your_wallet_name_on_card_label
        your_wallet_card_number_label
        your_wallet_expiry_date_label
        your_wallet_cvv_label
        your_wallet_cancel_add_card
        your_wallet_save_card
        your_wallet_payment_loading
        your_wallet_confirm_delete_heading
        your_wallet_confirm_delete_caption
        your_wallet_cancel_delete_card
        your_wallet_name_on_card_placeholder
      }
      strapiAccountModal_zh: strapiAccountModal(locale: {eq: "zh"}) {
        your_wallet_add_new_card_text
        your_wallet_default_card_text
        your_wallet_delete_card_text
        your_wallet_heading
        your_wallet_make_default_card_text
        your_wallet_name_on_card_label
        your_wallet_card_number_label
        your_wallet_expiry_date_label
        your_wallet_cvv_label
        your_wallet_cancel_add_card
        your_wallet_save_card
        your_wallet_payment_loading
        your_wallet_confirm_delete_heading
        your_wallet_confirm_delete_caption
        your_wallet_cancel_delete_card
        your_wallet_name_on_card_placeholder
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: {eq: "en"}) {
        invalid_number_error
        invalid_cvv_error
        invalid_card_type_error
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: {eq: "zh"}) {
        invalid_number_error
        invalid_cvv_error
        invalid_card_type_error
      }
    }
  `);
  const translations = gql[`strapiAccountModal_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];

  const [unMount$] = useState(new Subject());
  const [paymentCards, setPaymentCards] = useState([]);
  const [paymentJs, setPaymentJs] = useState(null);
  const [isAddingNewCard, setIsAddingNewCard] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [cardToDelete, setCardToDelete] = useState(undefined);
  const [defaultCard, setDefaultCard] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paymentJsReady, setPaymentJsReady] = useState(false);
  const [expiryDate, setExpiryDate] = useState('')
  const [cardInputErrors, setCardInputErrors] = useState([]);

  useEffect(() => {
    selectUserPaymentCards$.pipe(takeUntil(unMount$)).subscribe((cards) => {
      const primaryCard = cards.find((card) => card.is_primary);
      setPaymentCards(cards);
      setSelectedCard(primaryCard);
      setDefaultCard(primaryCard);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  useEffect(() => {
    if (paymentJs) {
      paymentJs.init(
        process.env.GATSBY_USTORE_PAYMENTJS_INTEGRATION_KEY,
        'modalCardNumber',
        'modalCVV',
        function (util) {
          if (!paymentJsReady) {
            setPaymentJsReady(true)
          }
          util.setNumberStyle({
            border: '1px solid #e5e7eb',
            width: '100%',
            height: '40px',
            padding: '0.5rem 1rem',
          });
          util.setCvvStyle({
            border: '1px solid #e5e7eb',
            width: '100%',
            height: '40px',
            padding: '0.5rem 1rem',
          });
          util.numberOn('input', function (data) {
            validateCardNumberInputs(data)
            if (data.numberLength === 0) {
              setCardValid(false);
              document.getElementById('modal_err_number').innerHTML = locale === 'en' ?
                '<div class="error">Card number is required.</div>' : '<div class="error">必填项目</div>';
            } else if (!data.validNumber && data.numberLength > 4) {
              setCardValid(false);
              document.getElementById('modal_err_number').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_number_error+'</div>' : '<div class="error">必填项目</div>';
            } else {
              setCardValid(true);
              document.getElementById('modal_err_number').innerHTML = '';
            }
          });
          util.cvvOn('input', function (data) {
            if (data.cvvLength === 0) {
              setCvvValid(false);
              document.getElementById('modal_err_cvv').innerHTML = locale === 'en' ?
                '<div class="error">CVV is required.</div>' : '<div class="error">必填项目</div>';
            } else if (!data.validCvv && data.cvvLength > 0 ) {
              setCvvValid(false);
              document.getElementById('modal_err_cvv').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_cvv_error+'</div>' : '<div class="error">必填项目</div>';
            } else {
              setCvvValid(true);
              document.getElementById('modal_err_cvv').innerHTML = '';
            }
          });
        }
      );
    } else {
      if (isAddingNewCard) {
        // eslint-disable-next-line
        setPaymentJs(new PaymentJs());
      }
    }
  }, [paymentJs, isAddingNewCard]);

  const interceptSubmit = async () => {
    if (isBusy === false) {
      setIsBusy(true);
      setCardInputErrors([]);

      const data = {
        card_holder: document.querySelector('#modalCardName').value,
        month: document.querySelector('#modalExpiryDate').value.split('/')[0],
        year: document.querySelector('#modalExpiryDate').value.split('/')[1],
      };

      paymentJs.tokenize(
        data,
        async function (token) {
          await createPaymentCard({
            transactionToken: token,
          })
            .pipe(tap(() => {
              setIsAddingNewCard(false);
            }))
            .toPromise()
            .catch(() => {
              setCardInputErrors(['Failed Adding New Payment Card']);
            });

          setIsBusy(false);
        },
        function (errors) {
          setIsBusy(false);
          setCardInputErrors(errors.map((error) => locale === 'en' ? error : "必填项目"));
        }
      );
    }
  };

  const cardImgFactory = (type) => {
    switch (type) {
      case 'visa':
        return Visa;
      case 'mastercard':
        return MC;
      default:
        return DefaultCC;
    }
  };

  const deleteCard = async () => {
    if (isBusy === false) {
      setIsBusy(true);
      await deletePaymentCard(cardToDelete)
        .toPromise()
        .catch(() => {
          setIsBusy(false);
          alert('Error deleting card');
        });
      setIsBusy(false);
      setShowDeleteModal(false);
    }
  };

  const makeDefaultCard = async () => {
    await setDefaultPaymentCard(selectedCard.id)
      .toPromise()
      .catch(() => {
        setIsBusy(false);
        alert('Error deleting card');
      });

    await getPaymentCards().toPromise();
  };

  const fixCardText = (e, formikProps) => {
    let text = e.target.value;
    if (text.length > 5) {
      return;
    }
    if (text.length === 2 && expiryDate.length === 1) {
      text += '/'
    } else if (text.length === 2 && expiryDate.length === 3) {
      text = text.substring(0, text.length - 1)
    }
    setExpiryDate(text)
    formikProps.setFieldValue('expiryDate', text)
  }

  const validateCardNumberInputs = (data) => {
    setCardInputErrors([]);

    if (!['visa', 'mastercard'].includes(data.cardType) && data.numberLength > 4) {
      setCardInputErrors([
        ...cardInputErrors,
        errTranslations.invalid_card_type_error
      ])
    }
  }

  const validate = (values) => {
    const errors = {};
    let cardDate = new Date();
    let expiredDate = false;
    let invalidDate = false;
    try {
      const cardDateValues = {
        month: document.querySelector('#modalExpiryDate').value.split('/')[0],
        year: document.querySelector('#modalExpiryDate').value.split('/')[1],
      };

      cardDate = setYear(cardDate, '20' + cardDateValues.year);
      cardDate = setMonth(cardDate, parseInt(cardDateValues.month) - 1);

      if (cardDate < startOfMonth(new Date())) {
        expiredDate = true;
      }

      if (cardDate.month > 12) {
        invalidDate = true;
      }

    } catch (e) {
      expiredDate = false;
      invalidDate = false;
    }

    if (!values.cardName) {
      errors.cardName = locale === 'en' ? 'Card name is required.' : '卡名是必填项';
    }

    if (!values.expiryDate) {
      errors.expiryDate = locale === 'en' ? 'Expiry date is required' : '到期日为必填项';
    } else if (/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(values.expiryDate) === false) {
      errors.expiryDate = locale === 'en' ? 'Invalid Format' : '无效的格式';
    } else if (invalidDate) {
      errors.expiryDate = locale === 'en' ? 'Invalid Date' : '失效日期';
    } else if (expiredDate) {
      errors.expiryDate = locale === 'en' ? 'Expired Date' : '过期日期';
    }

    return errors;
  };

  return (
    <Styled.WrapContent>
      <div className="flex justify-between mt-6">
        <h2>{!isAddingNewCard ? translations.your_wallet_heading : translations.your_wallet_add_new_card_text}</h2>
        {!isAddingNewCard && <button onClick={(e) => {e.preventDefault(); setIsAddingNewCard(true); setExpiryDate('')}} className='addCard'>{translations.your_wallet_add_new_card_text}</button>}
      </div>
      <Styled.Hr />

      {!isAddingNewCard && (
        <>
        <RadioGroup value={selectedCard} onChange={setSelectedCard} className="mt-0">
          {paymentCards.map((card) => {
            return (
              <RadioGroup.Option value={card} key={card.id} className="border-b border-gray-200">
                {({ checked }) => (
                  <span className={checked ? 'bg-blue-200' : ''}>
                    <div className="radioStyle">
                      <input
                        defaultChecked={checked}
                        id="push-everything"
                        name="push-notifications"
                        type="radio"
                        className="form-radio p-3 mt-6"
                      />
                      <img src={cardImgFactory(card.type)} alt={card.type} />
                      <span>
                        <b>{card.type.toUpperCase()}</b>
                        {card.is_primary && (
                          <div className='defaultCard flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                            <span>{translations.your_wallet_default_card_text}</span>
                          </div>
                        )}
                        <p>Credit card ending ***{card.last_4_digits}</p>
                        <button
                          onClick={() => {
                            setCardToDelete(card.id);
                            setShowDeleteModal(true);

                            setTimeout(() => {
                              if (document.querySelector('#deleteModal')) {
                                document.querySelector('#deleteModal').parentElement.style.zIndex = "11";
                              }
                            }, 10)

                          }}
                          className="deleteCard"
                        >
                          {translations.your_wallet_delete_card_text}
                        </button>
                      </span>
                    </div>
                  </span>
                )}
              </RadioGroup.Option>
            );
          })}

        </RadioGroup>
          <div className="flex justify-end mt-3">
            {!selectForPayment && (
              <button onClick={makeDefaultCard} className='makeDefault'>{translations.your_wallet_make_default_card_text}</button>
            )}
            {selectForPayment && (
              <button onClick={async () => {
                await makeDefaultCard()
                setCardCallback(selectedCard);
              }} className='makeDefault'>Use Card</button>
            )}
          </div>
        </>
      )}

      { !paymentJsReady && isAddingNewCard && <p className="text-center mt-10">Please Wait</p>}

      {isAddingNewCard && (
        <Formik
          enableReinitialize
          initialValues={ {
            cardName: '',
            cardNumber: '',
            expiryDate: '',
            cardCVV: '',
          } }
          validate={validate}
          onSubmit={ async () => {
            if (!cvvValid) {
              document.getElementById('modal_err_cvv').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_cvv_error+'</div>' : '<div class="error">必填项目</div>';
            }

            if (!cardValid) {
              document.getElementById('modal_err_number').innerHTML = locale === 'en' ?
                '<div class="error">'+errTranslations.invalid_number_error+'</div>' : '<div class="error">必填项目</div>';
            }

            if (cvvValid && cardValid && cardInputErrors.length === 0) {
              await interceptSubmit();
            }
          } }
        >{(formikProps) => {
          return (
            <Form style={{ visibility: paymentJsReady ? 'visible' : 'hidden' }}>
              <Styled.WrapNewCardModal>
                <div className="wrapField">
                  <p>{translations.your_wallet_name_on_card_label}</p>
                  <Field
                    type="text"
                    name="cardName"
                    id="modalCardName"
                    autoComplete="given-name"
                    className="mt-1 block w-full text-xs border-gray-300"
                  />
                  <ErrorMessage
                    name="cardName"
                    component="div"
                    className="mt-1 text-xs text-red-600"
                  />
                </div>
                <div className="wrapField">
                  <p>{translations.your_wallet_card_number_label}</p>
                  <div id="modalCardNumber" style={{ height: '40px' }} />
                  <div id="modal_err_number" className="mt-1 text-xs text-red-600"/>
                </div>
                <div className="wrapField flex gap-2">
                  <div>
                    <p>{translations.your_wallet_expiry_date_label}</p>
                    <Field
                      type="text"
                      name="expiryDate"
                      id="modalExpiryDate"
                      className="mt-1 block w-full text-xs border-gray-300"
                      value={expiryDate}
                      onChange={(e) => fixCardText(e, formikProps)}
                    />
                    <ErrorMessage
                      name="expiryDate"
                      component="div"
                      className="mt-1 text-xs text-red-600"
                    />
                  </div>
                  <div>
                    <p>{translations.your_wallet_cvv_label}</p>
                    <div id="modalCVV" style={{ height: '40px' }} />
                    <div id="modal_err_cvv" className={`mt-1`}/>
                  </div>
                </div>
                <Styled.FlexReverse>
                  {!isBusy && (
                    <button
                      className="whiteBtn"
                      onClick={() => {
                        setIsAddingNewCard(false);
                        setCardInputErrors([]);
                      }}
                    >
                      {translations.your_wallet_cancel_add_card}
                    </button>
                  )}
                  <button type="submit">
                    {isBusy
                      ? translations.your_wallet_payment_loading
                      : translations.your_wallet_save_card}
                  </button>
                </Styled.FlexReverse>

                <div style={{ marginTop: '80px', paddingBottom: '20px' }}>
                  {cardInputErrors.map((error) => (
                    <Danger message={error} />
                  ))}
                </div>
              </Styled.WrapNewCardModal>
            </Form>
          );
        }}</Formik>

      )}

      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        contentLabel={translations.your_wallet_delete_card_text}
        id="deleteModal"
      >
        <Styled.WrapModal>
          <h3>{translations.your_wallet_confirm_delete_heading}</h3>
          <p>{translations.your_wallet_confirm_delete_caption}</p>
          <Styled.Flex>
            <Styled.Button outline onClick={() => setShowDeleteModal(false)}>
              {translations.your_wallet_cancel_delete_card}
            </Styled.Button>
            <Styled.Button filled onClick={() => deleteCard()}>
              {!isBusy ? translations.your_wallet_delete_card_text : translations.your_wallet_payment_loading}
            </Styled.Button>
          </Styled.Flex>
        </Styled.WrapModal>
      </Modal>
    </Styled.WrapContent>
  );
};

Transactions.propTypes = {
  locale: PropTypes.string,
};

Transactions.defaultProps = {
  locale: 'en',
};

export default Transactions;
