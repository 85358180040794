import { CheckoutState, checkoutStore, Postcode } from './checkout.store';
import { checkoutQuery } from './checkout.query';
import { Observable, of, throwError } from 'rxjs';
import { authRequestHeaders, getLocalStorageItem, setLocalStorageItem } from '../utils';
import { cartQuery } from '../cart/cart.query';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ajax } from "rxjs/ajax";


export const selectCheckout$: Observable<CheckoutState> = checkoutQuery.select().pipe(
  switchMap((checkout) => {
    if (!checkout.address_id && !!getLocalStorageItem('uStoreCheckoutAddressId')) {
      checkout = {
        ...checkout,
        address_id: getLocalStorageItem('uStoreCheckoutAddressId'),
      };
    }

    if (!checkout.payment_card_id && !!getLocalStorageItem('uStoreCheckoutCardId')) {
      checkout = {
        ...checkout,
        payment_card_id: getLocalStorageItem('uStoreCheckoutCardId'),
      };
    }

    if (!checkout.delivery_due_date && !!getLocalStorageItem('uStoreDeliveryDueDate')) {
      checkout = {
        ...checkout,
        delivery_due_date: getLocalStorageItem('uStoreDeliveryDueDate'),
      };
    }

    if (!checkout.special_instructions && !!getLocalStorageItem('uStoreDeliveryInstructions')) {
      checkout = {
        ...checkout,
        special_instructions: getLocalStorageItem('uStoreDeliveryInstructions'),
      };
    }

    if (!checkout.accepted_by && !!getLocalStorageItem('uStoreDeliveryAcceptance')) {
      checkout = {
        ...checkout,
        accepted_by: getLocalStorageItem('uStoreDeliveryAcceptance'),
      };
    }

    if (!checkout.postcode && !!getLocalStorageItem('uStorePostcode')) {
      checkout = {
        ...checkout,
        postcode: getLocalStorageItem('uStorePostcode'),
      };
    }

    return of(checkout);
  })
);

export const selectCheckoutAddressId$: Observable<number> = checkoutQuery.select().pipe(
  switchMap((checkout) => {
    if (checkout.address_id) {
      return of(checkout.address_id);
    }

    if (!!getLocalStorageItem('uStoreCheckoutAddressId', undefined)) {
      return of(getLocalStorageItem('uStoreCheckoutAddressId'));
    }

    return of(null);
  })
);

export const selectPostcode$: Observable<Postcode> = checkoutQuery.select().pipe(
  switchMap((checkout) => {
    if (checkout.postcode) {
      return of(checkout.postcode);
    }

    if (!!getLocalStorageItem('uStorePostcode', undefined)) {
      return of(getLocalStorageItem('uStorePostcode'));
    }

    return of(null);
  })
);

export const updateCheckoutData = (data): void => {
  checkoutStore.update((state) => {
    return {
      ...state,
      data,
    };
  });
};

export const setCheckoutAddressId = (id): void => {
  setLocalStorageItem('uStoreCheckoutAddressId', id);
  checkoutStore.update({
    address_id: id,
  });
};

export const setCheckoutDeliveryLocationId = (id): void => {
  setLocalStorageItem('uStoreDeliveryLocationId', id);
  checkoutStore.update({
    delivery_location_id: id,
  });
};

export const setCheckoutDeliveryDueDate = (date): void => {
  setLocalStorageItem('uStoreDeliveryDueDate', date);
  checkoutStore.update({
    delivery_due_date: date,
  });
};

export const setCheckoutDeliveryInstructions = (instructions): void => {
  setLocalStorageItem('uStoreDeliveryInstructions', instructions);
  checkoutStore.update({
    special_instructions: instructions,
  });
};

export const setCheckoutAcceptance = (accepted_by): void => {
  setLocalStorageItem('uStoreDeliveryAcceptance', accepted_by);
  checkoutStore.update({
    accepted_by: accepted_by,
  });
};

export const setCheckoutCardId = (id): void => {
  setLocalStorageItem('uStoreCheckoutCardId', id);
  checkoutStore.update({
    payment_card_id: id,
  });
};

export const setCheckoutPostcode = (postcode): void => {
  setLocalStorageItem('uStorePostcode', postcode);
  checkoutStore.update({
    postcode: postcode,
  });
};

export const processCheckout = (data: {
  address_id: any,
  payment_card_id: any,
  delivery_due_date: any,
  delivery_location_id: any,
  special_instructions: any,
  accepted_by: any,
  coupon: any,
}): Observable<any> => {
  const url = `/api/cart/checkout`;
  const cartData = cartQuery.getValue();

  return ajax({
    url: url,
    method: 'POST',
    headers: authRequestHeaders(),
    body: JSON.stringify({
      address_id: data.address_id,
      payment_card_id: data.payment_card_id,
      delivery_due_date: data.delivery_due_date,
      amount: +cartData.subtotal,
      delivery_location_id: data.delivery_location_id,
      special_instructions: data.special_instructions,
      accepted_by: data.accepted_by,
      coupon: data.coupon,
    }),
  }).pipe(
      map(({ response }) => response),
      catchError((error) => {
        return throwError(error);
      })
  );
};

export const getOrderDetails = (order_number): Observable<any> => {
  return ajax({
    url: `/api/customer/order/${order_number}`,
    method: 'GET',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
};
