import React from 'react';
import PropTypes from 'prop-types';
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import EN from './../../../assets/images/lang-au.png';
import CN from './../../../assets/images/lang-cn.png';

import * as Styled from './styles';

const Language = ({locale}) => {
  const { language } = React.useContext(I18nextContext);
  const { changeLanguage } = useI18next();
  return (
  <>
    <Styled.Wrap>
      <button onClick={() => changeLanguage('en')} className={ language === 'en' ? `border border-blu-link` : 'border-grayu-D8D8D8'}>
        <img src={EN} alt="Australian" />
        <span>
          <span>English</span>
          <p>Australian</p>
        </span>
      </button>
      <button onClick={() => changeLanguage('zh')} className={ language === 'zh' ? `border border-blu-link` : 'border-grayu-D8D8D8'}>
        <img src={CN} alt="Chinese" />
        <span>
          <span>Chinese</span>
          <p>Mandarin</p>
        </span>
      </button>
    </Styled.Wrap>
  </>
  );
};

Language.propTypes = {
  lang: PropTypes.string
};

export default Language;
