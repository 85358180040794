import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  width: 100%;
  .potTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin-bottom: 24px;
  }
  .postLabel {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #292C2F;
  }
  a{
    color: #0963CD;
  }
`;

export const Btn = styled.div`
  ${tw`w-full`};
  svg{
    float: right;
    margin-bottom: 1rem;
  }
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
`;

export const Desktop = styled.div`
  ${tw`hidden sm:block`};
`;
