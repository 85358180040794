import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Modal from 'react-modal';
import * as Styled from './styles';
import AccountForm from './account';
import Orders from './orders';
import Language from './language';
import Wallet from './wallet';
import AccountIcon from './../../assets/images/user-account.png';
import tabs from './../../data/account';
import { selectUser$ } from '../../state/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import AccountMenu from './accountMenu';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import CloseIcon from './../ui/icons/close';

const Account = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiAccountModal_en: strapiAccountModal(locale: { eq: "en" }) {
        your_account_heading
        your_orders_heading
        your_transactions_heading
        your_wallet_heading
        language_preferences_heading
        your_email_subscriptions_heading
        your_wallet_tab_label
      }
      strapiAccountModal_zh: strapiAccountModal(locale: { eq: "zh" }) {
        your_account_heading
        your_orders_heading
        your_transactions_heading
        your_wallet_heading
        language_preferences_heading
        your_email_subscriptions_heading
        your_wallet_tab_label
      }
    }
  `);
  const translationsTabs = gql[`strapiAccountModal_${locale}`];
  const [unMount$] = useState(new Subject());
  const [user, setUser] = useState(null);
  const [showModalAccount, setShowModalAccount] = useState(false);

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  function getTabName(tab) {
    switch (tab.id) {
      case 1:
        return translationsTabs.your_account_heading;
      case 2:
        return translationsTabs.your_orders_heading;
      case 4:
        return translationsTabs.language_preferences_heading;
      case 6:
        return translationsTabs.your_wallet_tab_label;
    }
  }

  function showProfile() {
    if (!user.id) {
      window.location.href = '/login';
    } else {
      setShowModalAccount(true);
    }
  }

  function toggleModal() {
    setShowModalAccount(!showModalAccount);
  }

  return (
    <>
      <Styled.Desktop className="-mt-2.5">
        <button onClick={() => showProfile()} className="z-20 mt-0 sm:mt-2">
          <img src={AccountIcon} alt="Ubottle Account" />
        </button>
      </Styled.Desktop>
      <Styled.Mobile>
        <AccountMenu tabs={tabs} type="mob" user={user} locale={locale} />
      </Styled.Mobile>
      <Modal
        isOpen={showModalAccount}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        id="accountModal"
      >
        <Styled.Mobile>
          <AccountMenu tabs={tabs} user={user} locale={locale} />
        </Styled.Mobile>

        <Styled.Content>
          <Tabs>
            <Styled.WrapGrid>
              <Styled.Grid>
                <Styled.LeftGrid>
                  <p>{user?.email}</p>
                  <TabList>
                    {tabs.map((tab) => (
                      <Tab key={tab.id}>
                        <Styled.WrapList>
                          <span id={tab.icon}>{getTabName(tab)}</span>
                        </Styled.WrapList>
                      </Tab>
                    ))}
                  </TabList>
                </Styled.LeftGrid>
                <Styled.RightGrid>
                  <Styled.Button
                    onClick={() => setShowModalAccount(false)}
                    className="hidden sm:block"
                  >
                    <CloseIcon />
                  </Styled.Button>
                  <div className="block">
                    <TabPanel>
                      <AccountForm user={user} locale={locale} />
                    </TabPanel>
                    <TabPanel>
                      <Orders user={user} locale={locale} />
                    </TabPanel>
                    <TabPanel>
                      <Language user={user} locale={locale} />
                    </TabPanel>
                    <TabPanel>
                      <Wallet user={user} locale={locale} />
                    </TabPanel>
                  </div>
                </Styled.RightGrid>
                <div id="background"></div>
              </Styled.Grid>
            </Styled.WrapGrid>
          </Tabs>
        </Styled.Content>
      </Modal>
    </>
  );
};

Account.propTypes = {
  locale: PropTypes.string,
};

Account.defaultProps = {
  locale: 'en',
};

export default Account;
