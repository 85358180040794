import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getUserOver18, setLocalStorageItem } from '../../state/utils';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import Language from './../ui/Language/';
import * as Styled from './styles';

const AgeRestrict = () => {
  const { language } = React.useContext(I18nextContext);
  const [over18, setOver18] = useState(getUserOver18());
  const [isOpen, setIsOpen] = useState(!over18);

  useEffect(() => {
    if(isOpen===true){
      document.body.style.overflow = 'hidden';
    } else{
      document.body.style.overflow = 'unset';
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('#ageRestrict')) {
        document.querySelector('#ageRestrict').parentElement.style.zIndex = "2";
      }
    }, 500)
  }, []);

  function toggleModal() {
    setLocalStorageItem('uStoreOver18', true);
    setOver18(!over18);
    setIsOpen(!isOpen);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={toggleModal} shouldCloseOnOverlayClick={false} contentLabel="My dialog" id="ageRestrict">
      <Styled.Lang>
        <p className="title">Language Options</p>
        <Language lang={language} />
        <hr />
      </Styled.Lang>

      <Styled.Wrap id='ageRestrictWrap'>
        <h3 className="thin">{language === 'en' ? 'Are you over 18?' : '你18岁了吗'}</h3>
        <p>
          {language === 'en'
            ? 'This website requires you to be 18 years or older to enter.'
            : '本网站要求您年满 18 岁才能进入'}
        </p>
        <button onClick={toggleModal} className="age-toggle">
          {language === 'en' ? 'Yes I am' : '是的'}
        </button>
        <button className="white" onClick={() => (window.location.href = language === 'en' ? '/restriction' : '/zh/restriction')}>
          {language === 'en' ? 'No, Exit Website' : '不'}
        </button>
      </Styled.Wrap>
    </Modal>
  );
};

export default AgeRestrict;
