import React from 'react';
import PropTypes from 'prop-types';

import MainNavi from './MainNavi';
import Age from './../AgeRestrict';
import PwaInstall from "../PwaInstall";
import PwaQrCode from "../PwaQrCode";

import * as Styled from './styles';

const Header = ({ page, locale }) => {
  return (
    <>
      <div className={page === 'home' || page === 'shop' || 'individual-product-page' ? 'block' : 'hidden sm:block'}>
        <div className={page === 'shop' ? 'bg-grayu-semimed xl:bg-white' : 'xl:bg-white'}>
          <div className={page === 'home' ? 'mobileHeaderHome' : 'lg:header'}>
            <Styled.Wrapper>
              <MainNavi page={page} locale={locale} />
              <Age />
              <PwaInstall />
              <PwaQrCode />
            </Styled.Wrapper>
          </div>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  locale: PropTypes.string,
};

Header.defaultProps = {
  locale: 'en',
};

export default Header;
