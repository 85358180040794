import React from 'react';
import * as Styled from './styles';
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next";
import EN from 'assets/images/au.png';
import CN from 'assets/images/cn.png';
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const Language = ({ locale }) => {
  const { changeLanguage } = useI18next();

  const gql = useStaticQuery(graphql`
    query {
      strapiAccountModal_en: strapiAccountModal(locale: {eq: "en"}) {
        language_preferences_caption
        language_preferences_heading
        language_preferences_save_button_label
        language_preferences_text
      }
      strapiAccountModal_zh: strapiAccountModal(locale: {eq: "zh"}) {
        language_preferences_caption
        language_preferences_heading
        language_preferences_save_button_label
        language_preferences_text
      }
    }
  `);
  const translations = gql[`strapiAccountModal_${locale}`];

  return (
    <Styled.WrapContent>
      <h2>{translations.language_preferences_heading}</h2>
      <Styled.Hr />
      <Styled.Grid>
        <Styled.Colspan1>
          <span>{translations.language_preferences_caption}</span>
          <p>{translations.language_preferences_text}</p>
        </Styled.Colspan1>
        <Styled.Colspan1>
          <Styled.Flex onClick={() => changeLanguage('en')} className={ locale === 'en' ? `border border-blue-300` : ''}>
            <img src={EN} alt="Australian" />
            <span>
              <span>English</span>
              <p>English - Australian</p>
            </span>
          </Styled.Flex>
          <Styled.Flex onClick={() => changeLanguage('zh')} className={ locale === 'zh' ? `border border-blue-300` : ''}>
            <img src={CN} alt="Chinese" />
            <span>
              <span>Chinese</span>
              <p>Chinese - Simplified</p>
            </span>
          </Styled.Flex>
        </Styled.Colspan1>
      </Styled.Grid>
    </Styled.WrapContent>
  );
};

Language.propTypes = {
  locale: PropTypes.string,
};

Language.defaultProps = {
  locale: 'en',
};

export default Language;
