import styled from "styled-components";
import tw from 'twin.macro';
import iconAccount from './icons/user-account.png'
import iconEmail from './icons/user-email.png'
import iconLanguage from './icons/user-language.png'
import iconOrders from './icons/user-orders.png'
import iconTransaction from './icons/user-transaction.png'
import iconWallet from './icons/user-wallet.png'

export const Wrapper = styled.div`
  ${tw`bg-white bg-opacity-75 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`};
`;

export const InnerWrapper = styled.div`
  ${tw`relative w-auto my-0 sm:my-6 mx-auto w-full sm:w-6/12`};
`;

export const Content = styled.div`

`;

export const WrapGrid = styled.div`
  ${tw`relative flex-auto`};
`;

export const Grid = styled.div`
  height:550px;
  ${tw`flex h-screen sm:h-auto sm:mt-auto`};
`;

export const LeftGrid = styled.div`
  background: #FCFCFC;
  width: 234px;
  ${tw`col-span-1 text-left hidden sm:block antialiased`};
  p{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #8C8C8C;
    padding: 8px 16px;
  }
  ul{
    height: 32rem;
    .react-tabs__tab--selected{
      ${tw`bg-grayu-semimed`};
    }
  }
  svg{
      ${tw`h-7 w-7 bg-gray-200 text-brown-semidark rounded-full p-1 mr-2`}
  }
`;

export const RightGrid = styled.div`
  padding: 24px 0;
  width: 568px;
  margin:0 auto;
  ${tw`col-span-1`};
  @media (max-width: 768px) {
    ${tw`w-full`};
  }
`;

export const Button = styled.button`
  color: #BCC4CA;
  margin-bottom: 24px;
  ${tw`w-full ml-auto border-0 text-xl3 leading-none font-semibold outline-none focus:outline-none`};
  svg{${tw`float-right`};}
  :hover{color: #000000;}
`;

export const Opacity = styled.div`
  ${tw`opacity-25 fixed inset-0 z-40 bg-black`};
`;

export const WrapList = styled.div`
  ${tw`flex text-xssemi`};
  span{
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #000000;
    margin: 8px 16px;
    display: flex;
    align-items: center;
    ${tw`text-sm cursor-pointer`}
    :before{
      width: 24px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  #user-account:before{
    content: url(${iconAccount});
  };
  #user-orders:before{
    content: url(${iconOrders});
  };
  #user-transaction:before{
    content: url(${iconTransaction});
  };
  #user-language:before{
    content: url(${iconLanguage});
  };
  #user-email:before{
    content: url(${iconEmail});
  };
  #user-wallet:before{
    content: url(${iconWallet});
  };
`;

export const Desktop = styled.div`
  ${tw`hidden sm:block`};
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
`;
