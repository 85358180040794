import React from 'react';
import PropTypes from 'prop-types';
import Account from './../../Account';
import { logout } from '../../../state/user/user.service';
import * as Styled from './styles';
import { useI18next } from "gatsby-plugin-react-i18next";

const Language = ({ user, locale }) => {
  const { changeLanguage } = useI18next();

  const selectLanguage = async (e) => {
    await changeLanguage(e.target.value)
  }

  return (
    <>
      <Styled.Wrap>
        {user?.id && (
          <button onClick={() => logout()} className="hidden sm:block">
            {locale === 'en' ? 'Logout' : '登出'}
          </button>
        )}
        <select onChange={selectLanguage} value={locale}>
          <option value={"en"}>English</option>
          <option value={"zh"}>中文</option>
        </select>
        <Account locale={locale}/>
      </Styled.Wrap>
    </>
  );
};

Language.propTypes = {
  user: PropTypes.any,
  locale: PropTypes.string,
};

Language.defaultProps = {
  locale: 'en',
};

export default Language;
