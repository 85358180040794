import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, take } from 'rxjs/operators';
import { authRequestHeaders } from '../utils';
import { userStore } from "./user.store";
import { setCheckoutCardId } from "../checkout/checkout.service";

export const createPaymentCard = (data) => {
  return ajax({
    url: `/api/customer/payment-cards`,
    method: 'POST',
    headers: authRequestHeaders(),
    body: data
  }).pipe(
    map(({ response }) => {
      userStore.update((state) => {
        return {
          ...state,
          payment_cards: [...state.payment_cards, response]
        }
      })
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const getPaymentCards = () => {
  return ajax({
    url: `/api/customer/payment-cards`,
    method: 'GET',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => {
      userStore.update((state) => {
        return {
          ...state,
          payment_cards: response
        }
      })
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const setDefaultPaymentCard = (card_id) => {
  return ajax({
    url: `/api/customer/payment-cards/${card_id}/set_default`,
    method: 'PUT',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => {
      setCheckoutCardId(card_id);
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};

export const deletePaymentCard = (card_id) => {
  return ajax({
    url: `/api/customer/payment-cards/${card_id}`,
    method: 'DELETE',
    headers: authRequestHeaders(),
  }).pipe(
    map(({ response }) => {
      getPaymentCards().pipe(take(1)).subscribe();
      return response;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
};
