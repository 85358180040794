import styled from 'styled-components';
import tw from 'twin.macro';

export const Button = styled.button`
  line-height: 40px;
  ${tw`border text-ft-16 tracking-ls-5 px-4`};
  ${({ black }) => black && tw`bg-black text-white border-black`};
  ${({ border }) => border && tw`bg-white text-black border-black`};
  ${({ red }) => red && tw`bg-redu-btn text-white`};
  ${({ redBorder }) => redBorder && tw`bg-white text-redu-btn border-redu-btn`};
  ${({ gray }) => gray && tw`bg-grayu-medium2 text-grayu-tx`};
  ${({ grayBorder }) => grayBorder && tw`bg-white text-grayu-ca border-grayu-ca`};
  ${({ bare }) => bare && tw`bg-white text-black border-white`};
`;
