import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import * as Styled from './styles';

const Button = ({ black, border, red, redBorder, gray, grayBorder, children, bare}) => (
    <motion.div whileHover={{ scale: 1 }} whileTap={{ scale: 0.95 }}>
        <Styled.Button 
          black={black} 
          border={border} 
          red={red}
          redBorder={redBorder}
          gray={gray}
          grayBorder={grayBorder}
          bare={bare}
        >
            {children}
        </Styled.Button>
    </motion.div>
);

Button.propTypes = {
  black: PropTypes.bool,
  border: PropTypes.bool,
  red: PropTypes.bool,
  redBorder: PropTypes.bool,
  gray: PropTypes.bool,
  grayBorder: PropTypes.bool,
  bare: PropTypes.bool,
};

export default Button;
