import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Styled from './styles';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import DatePicker from "react-datepicker";
import { useI18next } from 'gatsby-plugin-react-i18next';
import { calculateAge, interpolateTranslation } from "../../../state/utils";
import { updateProfile } from "../../../state/user/user.service";
import { take } from "rxjs/operators";
import { startOfDay, startOfToday } from "date-fns";

const Account = ({ user, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiAccountModal_en: strapiAccountModal(locale: { eq: "en" }) {
        your_account_birth_date_field_label
        your_account_change_login_button_label
        your_account_changing_passwords_heading
        your_account_changing_passwords_text
        your_account_contact_field_label
        your_account_email_field_label
        your_account_first_name_field_label
        your_account_heading
        your_account_last_name_field_label
      }
      strapiAccountModal_zh: strapiAccountModal(locale: { eq: "zh" }) {
        your_account_birth_date_field_label
        your_account_change_login_button_label
        your_account_changing_passwords_heading
        your_account_changing_passwords_text
        your_account_contact_field_label
        your_account_email_field_label
        your_account_first_name_field_label
        your_account_heading
        your_account_last_name_field_label
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: {eq: "en"}) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        over_18_required_for_an_account
        minimum_characters
        maximum_characters
        valid_phone_number
        agree_to_terms
        agree_to_privacy
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: {eq: "zh"}) {
        email_address_required
        invalid_email_address
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        over_18_required_for_an_account
        minimum_characters
        maximum_characters
        valid_phone_number
        agree_to_terms
        agree_to_privacy
        enter_billing_address
        enter_city
        enter_state
        enter_postal_code
        enter_address_label
      }
    }
  `);
  const translations = gql[`strapiAccountModal_${locale}`];
  const [formEnabled, setFormEnabled] = useState(true)
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const editText = locale === 'en' ? 'Edit' : '编辑'
  const saveText = locale === 'en' ? 'Save' : '保全'
  const cancelText = locale === 'en' ? 'Cancel' : '取消'

  const { navigate } = useI18next();
  const validate = (values) => {
    const errors = {};

    const today = startOfToday();
    const selectedDate = values.birth_date ? startOfDay(values.birth_date) : today;

    if(calculateAge(selectedDate) < 18 ||
      (!values.birth_date)){
      errors.birth_date= errTranslations.over_18_required_for_an_account
    }

    if ((values.first_name && values.first_name.length < 3) || (!values.first_name)) {
      errors.first_name = interpolateTranslation(errTranslations.minimum_characters, [`CHAR_COUNT_TOKEN`], [`2`])
    } else if (values.first_name.length > 50) {
      errors.first_name = interpolateTranslation(errTranslations.maximum_characters, [`CHAR_COUNT_TOKEN`], [`50`])
    }

    if ((values.last_name && values.last_name.length < 3) || (!values.last_name)) {
      errors.last_name = interpolateTranslation(errTranslations.minimum_characters, [`CHAR_COUNT_TOKEN`], [`2`])
    } else if (values.last_name.length > 50) {
      errors.last_name = interpolateTranslation(errTranslations.maximum_characters, [`CHAR_COUNT_TOKEN`], [`50`])
    }

    if (!values.email) {
      errors.email = errTranslations.email_address_required
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = errTranslations.invalid_email_address
    }

    if (!values.birth_date) {
      errors.birth_date= errTranslations.over_18_required_for_an_account
    }

    if (!values.phone || isNaN(values.phone) || values.phone.length < 10 ) {
      errors.phone= errTranslations.valid_phone_number
    }

    return errors;
  };


  return (
    <Styled.WrapContent>
      <h2>{translations.your_account_heading}</h2>
      <Styled.Hr />
      <Formik
        enableReinitialize
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
          phone: user?.phone,
          birth_date: user?.birth_date,
        }}
        onSubmit={async (values, { resetForm }) => {
          await updateProfile(values).pipe(take(1)).subscribe((d) => {
            setFormEnabled(true);
          })
        }}
        validate={validate}
      >
        {(formikProps) => {
          return (
            <Form>
              <Styled.Grid>
                <Styled.Colspan3>
                  <span>{translations.your_account_first_name_field_label}</span>
                  <Field type="text" id="first_name" name="first_name" disabled={formEnabled}/>
                  <ErrorMessage name="first_name" component="div" className="errorMessage"/>
                </Styled.Colspan3>
              </Styled.Grid>
              <Styled.Grid>
                <Styled.Colspan3>
                  <span>{translations.your_account_last_name_field_label}</span>
                  <Field type="text" name="last_name" disabled={formEnabled}/>
                  <ErrorMessage name="last_name" component="div" className="errorMessage"/>
                </Styled.Colspan3>
              </Styled.Grid>
              <Styled.Grid>
                <Styled.Colspan2>
                  <span>{translations.your_account_email_field_label}</span>
                  <Field type="email" name="email" onChange={() => {}} disabled/>
                  <ErrorMessage name="email" component="div" className="errorMessage"/>
                </Styled.Colspan2>
                <Styled.Colspan1>
                  <span>{translations.your_account_contact_field_label}</span>
                  <Field type="text" name="phone" disabled={formEnabled}/>
                  <ErrorMessage name="phone" component="div" className="errorMessage"/>
                </Styled.Colspan1>
              </Styled.Grid>
              <Styled.Grid>
                <Styled.Colspan3>
                  <span>{translations.your_account_birth_date_field_label}</span>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    name="birth_date"
                    className={`bg-white ${formEnabled ? 'disabled' : ''}`}
                    selected={formikProps.values.birth_date ? new Date(formikProps.values.birth_date) : null}
                    disabled={formEnabled}
                    onChange={(val) => {
                      formikProps.setFieldValue('birth_date', val);
                    }}
                    showYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    dropdownMode="select"
                  />
                  <ErrorMessage name="birth_date" component="div" className="errorMessage"/>
                </Styled.Colspan3>
              </Styled.Grid>
              {!formEnabled && (
                <div className="flex justify-end">
                  <button className="mr-2" onClick={() => {
                    formikProps.resetForm();
                    setFormEnabled(true)
                  }}>{cancelText}</button>
                  <button type="submit">{saveText}</button>
                </div>
              )}
              {formEnabled && (
                <div className="flex justify-end">
                  <button onClick={() => setFormEnabled(false)}>{editText}</button>
                </div>
              )}

              {!['facebook', 'google', 'apple'].includes(user?.auth_provider) && (
                <>
                  <br/>
                  <Styled.Notes>
                    <label htmlFor="comments">{translations.your_account_changing_passwords_heading}</label>
                    <p>{translations.your_account_changing_passwords_text}</p>
                  </Styled.Notes>

                  <div className="flex justify-end">
                    <button onClick={async (e) => {
                      e.preventDefault();
                      await navigate('/change-password/');
                    }}>{translations.your_account_change_login_button_label}</button>
                  </div>
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </Styled.WrapContent>
  );
};

Account.propTypes = {
  locale: PropTypes.string,
};

Account.defaultProps = {
  locale: 'en',
};

export default Account;
