import styled from "styled-components";
import tw from 'twin.macro';

export const WrapContent = styled.h2`
  ${tw`text-left`};
  h2{
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 10px;
  };
  button{
    ${tw`text-base py-2 px-6 text-white bg-black border border-black hover:text-black hover:bg-white`};
  }
`;

export const Hr = styled.div`
  height: 1px;
  border-top: 1px solid #D6D6D6;
  margin: 16px 0 24px;
`;

export const Grid = styled.div`
  ${tw`grid grid-cols-2 gap-4`};
`;

export const Colspan1 = styled.div`
  ${tw`col-span-2 sm:col-span-1`};
  span{
    letter-spacing: 0.03em;
    ${tw`text-xssemi`}
  }
  p{
    color: #737373;
    letter-spacing: 0.03em;
    ${tw`text-xssemi`}
  }
`;

export const Flex = styled.div`
  ${tw`flex gap-2 p-2 cursor-pointer`};
`;
