import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { isMobileSafari, isMobile, isIOS, isAndroid } from 'react-device-detect';
import SvgLogo from '../../svg/ustorelogo.svg';
import A2hs from '../../svg/a2hs.svg';
import CloseIcon from '../../svg/close.svg';
import * as Styled from './styles';
import { getLocalStorageItem, setLocalStorageItem } from "../../state/utils";

const PwaInstall = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [componentMounted, setComponentMounted] = useState(false);
  const [displayMode, setDisplayMode] = useState('browser');


  useEffect(() => {
    let isTooSoon = true;
    window.addEventListener('beforeinstallprompt', (e) => {
      if (isTooSoon && isMobile && !isMobileSafari) {
        e.preventDefault();
        setDeferredPrompt(e);
        setTimeout(function () {
          isTooSoon = false;
          if (getLocalStorageItem('uStorePromptPwaInstall') !== false) {
            setIsOpen(true);
          }
        }, 2000);
      }
    });

    setComponentMounted(true);

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      setDisplayMode('twa');
    } else if (navigator.standalone || isStandalone) {
      setDisplayMode('standalone');
    }

    if (isIOS && getLocalStorageItem('uStorePromptPwaInstall') !== false) {
      setIsOpen(true)
    }
  }, []);


  const toggleModal = () => {
    setLocalStorageItem('uStorePromptPwaInstall', false);
    setIsOpen(!isOpen);
  }

  if (componentMounted && isIOS && displayMode === 'browser') {
    return (
      <Modal
        isOpen={isOpen && isMobileSafari}
        shouldCloseOnOverlayClick={true}
        id="pwaInstallModal"
      >
        <img src={CloseIcon} alt={''} width={20} className={'float-right'} onClick={toggleModal} />
        <Styled.LogoWrap>
          <Styled.Squares />
          <Styled.Squares />
          <img src={SvgLogo} alt="" />
          <Styled.Squares />
          <Styled.Squares />
        </Styled.LogoWrap>
        <div>
          <Styled.Heading>Install App</Styled.Heading>
          <Styled.Instructions>
            To install the free USTORE web app please click this <img src={A2hs} alt={''} width={20}/> and
            select 'Add to Home Screen'
          </Styled.Instructions>
        </div>
        <Styled.Footer>
          <Styled.Text>
            Just tap <img src={A2hs} alt={''} /> then 'Add to Home Screen'
          </Styled.Text>
        </Styled.Footer>
      </Modal>
    );
  }

  if (componentMounted && isAndroid &&  displayMode === 'browser') {
    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        id="pwaInstallModal"
      >
        <img src={CloseIcon} alt={''} width={20} className={'float-right'} onClick={toggleModal} />
        <Styled.LogoWrap>
          <Styled.Squares />
          <Styled.Squares />
          <img src={SvgLogo} alt="" />
          <Styled.Squares />
          <Styled.Squares />
        </Styled.LogoWrap>
        <div>
          <Styled.Heading>Install App</Styled.Heading>
          <Styled.Instructions>
            Install this application for quick and easy access when you are on the go.
          </Styled.Instructions>
        </div>
        <Styled.Footer>
          <Styled.Text>
            <span onClick={() => {
              try {
                setIsOpen(false);
                deferredPrompt.prompt();
              } catch (e){}
            }}>Install</span>
          </Styled.Text>
        </Styled.Footer>
      </Modal>
    );
  }

  return null;
};

export default PwaInstall;
