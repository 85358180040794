import styled from "styled-components";
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`absolute inset-0 overflow-hidden`};
`;

export const WrapTransition = styled.div`
  ${tw`fixed inset-y-0 left-0 max-w-full flex`};
`;

export const WrapContent = styled.div`
  ${tw`relative w-screen max-w-md`};
`;

export const InnerContent = styled.div`
  ${tw`h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll`};
`;

export const HeaderContent = styled.div`
  ${tw`px-4 sm:px-6`};
  b{
    color: #2E2E2E;
    letter-spacing: 0.04em;
    ${tw`text-xl3 font-bold pt-2 pl-2`};
  }
  p{
    color: #8C8C8C;
    letter-spacing: 0.03em;
    ${tw`text-xssemi tracking-wide mt-6 pl-2`};
  }
`;

export const BodyContent = styled.div`
  ${tw`mt-2 relative flex-1 px-4 sm:px-6`};
`;

export const BottomOpen = styled.div`
  ${tw`w-full py-4 px-4 z-20 gap-4 mb-4`};
  button{
    letter-spacing: 0.05em;
    ${tw`w-full bg-black border border-black text-white py-2 my-2 text-base`};
    :nth-child(2){
      ${tw`bg-white text-black`};
    }
  }
  .white{
    ${tw`w-full bg-white border border-black text-black`};
  }
`;