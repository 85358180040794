import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { getCustomerOrders } from '../../../state/user/user.service';
import { take } from 'rxjs/operators';
import { format, parseISO } from 'date-fns';
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const Orders = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiAccountModal_en: strapiAccountModal(locale: {eq: "en"}) {
        your_orders_heading
        your_orders_order_id_column_header
        your_orders_status_column_header
        your_orders_date_column_header
      }
      strapiAccountModal_zh: strapiAccountModal(locale: {eq: "zh"}) {
        your_orders_heading
        your_orders_order_id_column_header
        your_orders_status_column_header
        your_orders_date_column_header
      }
    }
  `);
  const translations = gql[`strapiAccountModal_${locale}`];

  const [orders, setOrders] = useState([]);

  const statusFactory = (status_id) => {
    switch (status_id) {
      case 1:
        return locale === 'en' ? 'Waiting Confirmation' : '等待确认';
      case 2:
        return locale === 'en' ? 'Confirmed' : '确认的';
      case 3:
        return locale === 'en' ? 'Confirmed' : '确认的';
      case 4:
        return locale === 'en' ? 'Transit' : '过境';
      case 5:
        return locale === 'en' ? 'Delivered' : '发表';
      case 6:
        return locale === 'en' ? 'Cancelled' : '取消';
      case 7:
        return locale === 'en' ? 'Refunded' : '已退款';
      default:
        return locale === 'en' ? 'NA' : 'NA';
    }
  };

  useEffect(() => {
    getCustomerOrders()
      .pipe(take(1))
      .subscribe((customerOrders) => {
        setOrders(customerOrders);
      });
  }, []);

  return (
    <Styled.WrapContent>
      <h2>{translations.your_orders_heading}</h2>
      <Styled.Hr />
      <Styled.Flex>
        <Styled.TableWrap>
          <table className="w-auto">
            <thead>
            <tr>
              <th scope="col">{translations.your_orders_order_id_column_header}</th>
              <th scope="col">{translations.your_orders_status_column_header}</th>
              <th scope="col">{translations.your_orders_date_column_header}</th>
            </tr>
            </thead>
            <tbody>
            {orders.map((item) => (
              <tr key={item.id}>
                <td>
                  <div><a href={`/track-order/?order_number=${item.order_number}`} className='text-blue-700'>{item.order_number}</a></div>
                </td>
                <td>
                  <span>{statusFactory(item.status)}</span>
                </td>
                <td className="text-xs text-gray-500">
                  {format(parseISO(item.created_at), 'PP')}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </Styled.TableWrap>
      </Styled.Flex>
    </Styled.WrapContent>
  );
};

Orders.propTypes = {
  locale: PropTypes.string,
};

Orders.defaultProps = {
  locale: 'en',
};

export default Orders;
