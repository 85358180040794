import styled from 'styled-components';
import tw from 'twin.macro';

export const Layout = styled.main`
  ${tw`flex flex-col min-h-screen`};
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #171717;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #60666A;
    padding: 0;
    margin: 0;
  }

`;
