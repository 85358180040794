import { Store } from "@datorama/akita"


export interface GlobalTriggersState {
  accountModalTrigger: any;
  pwaInstallTrigger: any;
  toastTrigger: any;
}

export class GlobalTriggersStore extends Store<GlobalTriggersState> {
  constructor() {
    super({}, { name: "global-triggers", resettable: true })
  }
}

export const globalTriggersStore = new GlobalTriggersStore()
