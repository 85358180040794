import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby-plugin-react-i18next';

export const Wrap = styled.div`
  ${tw`mx-4 xl:mx-12 flex-grow`};
  padding: 20px 10px;
  .lang{
    margin-bottom: 16.5px;
    margin-top: 10px;
  }
`;

export const MenuLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #452020;
  :hover{
    color: #000000;
  }
`;

export const NavWrapLinks = styled.div`
  ${tw`py-0 space-y-2`};
`;

export const NavLinks = styled(Link)`
  ${tw`w-full flex px-4 py-2 text-lg text-black tracking-wide`};
`;

export const FakeLinks = styled.div`
  ${tw`w-full flex px-4 py-2 text-lg text-black tracking-wide`};
  &:hover {
   cursor: pointer;
  }
`;

export const NavMiniLinks = styled(Link)`
  ${tw`w-full flex px-4 py-2 text-sm text-black tracking-wide`};
`;

export const PostCode = styled.div`
  ${tw`w-full text-center my-auto`};
  .flex-div{
    ${tw`flex flex-col`};
  }
  svg, span, button{
    ${tw`inline text-brown-darker`};
  }
  p{
    line-height: 12px;
    letter-spacing: 0.08em;
    ${tw`text-mini uppercase text-grayu-medium`};
  }
  span{
    ${tw`leading-lh-135 text-sm text-brown-darker tracking-ls-3 px-1`};
  }
`;

export const MobWrap = styled.div`
  ${tw`bg-white divide-y-2 divide-gray-50`};
`;

export const Desktop = styled.div`
  ${tw`hidden`};
  @media (min-width: 992px) {
    ${tw`block`};
  }
`;

export const Mobile = styled.div`
  @media (min-width: 992px) {
    ${tw`hidden`};
  }
  @media (max-width: 991px) {
    ${tw`block`};
  }
`;

export const AddToCart = styled.div`
  ${tw`mt-6 text-center h-16`};
  a{
    button{
      ${tw`w-full`};
    }
  }
`;

export const DeliveryBlock = styled.div`
  letter-spacing: 0.04em;
  margin-top: 32px;
  ${tw`pt-6 px-4 mt-1 text-xssemi text-brown-semidark`}
  p{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #4D4545;
  }
  p{${tw`m-0`}}
  p:nth-child(2){
    letter-spacing: 0.05em;
    color: #9B9999;
    ${tw`text-xs`}
  }
  button{
    ${tw`text-blue-700 m-0`}
  }
`;
