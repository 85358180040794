import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import "react-datepicker/dist/react-datepicker.css";

export default createGlobalStyle`
  body {
    ${tw`m-0 bg-white sm:bg-gray-50`};
  }

  a {
    ${tw`text-brown-basic hover:text-brown-dark cursor-pointer`};
  }

  p + p {
    ${tw`mt-3`};
  }
  input[type="radio"],
  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  radio, select{
    ${tw`w-full h-10 py-2 px-4 focus:outline-none focus:ring-opacity-0 cursor-pointer`};
  }

  input{
    ${tw`w-full h-10 py-2 px-4 focus:outline-none focus:ring-4 focus:border-blue-400 focus:ring-opacity-50 focus:ring-blue-100`};
  }

  .load{
    ${tw`flex flex-col justify-center items-center h-screen`};
  }

  input{
    height: 40px;
    border: 1px solid #D6D6D6;
    border-radius: 2px;
    margin: 4px 0px;
    padding: 8px 10px;
    font-size: 13px;
    line-height: 135%;
    align-items: center;
    letter-spacing: 0.3px;
    color: #383C3F;
  }

  select{
    ${tw`text-brown-basic text-base rounded-sm bg-white`};
  }

  .containner {
    padding-bottom:6rem;
    padding: 0 1rem 6rem 1rem;
    margin: 0 auto;
  }

  .col-wide{width:90rem;}
  .col-boxed{width:82rem;}

  .show{
    display: block !important;
  }
  .hide{
    display:none;
  }

  .imgLogo{
    width:100px;
  }

  .form-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    border-radius: 100%;
    height: 1em;
    width: 1em;
    color: #0963CD;
    background-color: #DEE4E9;
    border-color: #e2e8f0;
    border-width: 1px;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-size: 130% 130%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .form-radio:checked {
    background-color: currentColor;
  }

  .error{
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #EB5656;
  }

  .disabledBtn{
    ${tw`bg-grayu-medium2 text-brown-lightmd w-full py-2.5 mt-6`};
  }

  .enabledBtn{
    ${tw`bg-blacku-default text-white w-full py-2.5 mt-6`};
  }

  .errorMessage{
    ${tw`my-1 pb-2 text-xs text-redu-mdlight`};
  }
  .errorInput{
    ${tw`bg-redu-light border border-redu-light text-redu-mdlight focus:outline-none`};
  }
  .errorIconShow{
    ${tw`absolute right-2 top-4 block`};
  }
  .errorIconShow2{
    ${tw`absolute right-2 top-3 block`};
  }
  .errorIconHide{
    ${tw`absolute right-2 top-3 hidden`};
  }
  .iconChecker{
    ${tw`absolute right-2 top-4 block`};
  }
  .checkBoxError:after {
    ${tw`bg-redu-light text-redu-light rounded-sm border border-redu-mdlight`};
    content: 'nn';
  }

  .react-autosuggest__container--open ul{
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 4px;
    ::-webkit-scrollbar {
      ${tw`hidden`};
    }
    ${tw`absolute w-full bg-brown-darker text-left text-white text-sm h-40 overflow-y-scroll rounded-md shadow-md mt-1`};
    li{
      font-size: 11px;
      line-height: 13.67px;
      display: flex;
      align-items: center;
      letter-spacing: 0.09em;
      color: #C0C4CF;
      padding: 8px;
      :hover{background: #D8DAE0; color: #0D090E; border-radius: 2px;}
      ${tw`cursor-pointer `};
    }
  }

  .ReactModal__Overlay{
    background-color: rgb(255 255 255 / 95%) !important;
  }
  .ReactModal__Content{
    border: 2px solid #000 !important;
    ${tw`md:w-8/12 lg:w-6/12 mx-auto sm:h-96 mt-16`};
  }

  #ageRestrict{
    width: 480px;
    height: 753px;
    padding: 16px !important;
  }

  #pwaInstallModal, #pwaQrCodeModal {
    width: 302px;
    height: auto;
    padding: 16px !important;
    border-radius: 16px !important;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    position: relative !important;
    border: none !important;
    overflow: hidden !important;
    inset: inherit !important;
  }

  #accountModal{
    width: 850px;
    min-height: 550px;
    padding:0 !important;
    ${tw`inset-0 bg-gray-100`};
  }

  #deleteModal{
    ${tw`mt-32`};
    width: 500px;
    height: 203px;
    padding: 16px !important;
    @media (max-width: 1640px) {
      width: auto;
      max-width: 600px;
    }
  }

  #newCardModal{
    width: 600px;
    height: 500px;
    ${tw`mt-16`};
  }

  #viewProductModal{
    overflow: inherit !important;
    width: 919px;
    ${tw` mt-16`};
  }

  #postCodeModal{
    max-width: 346px;
    height: 206px;
    overflow: visible !important;
    letter-spacing: 0.04em;
    padding: 14px !important;
    margin-top: 200px;
    .react-autosuggest__container--open ul{
      ${tw`w-11/12`};
    }
  }

  #newAddressModal{
    overflow-y: scroll !important;
    letter-spacing: 0.04em;
    height: 52vh;
    ${tw`w-10/12 xl:w-6/12 mt-16`};
    h3{
      ${tw`mt-10 mb-4`};
    }
  }
  @media screen and (min-width: 1900px) {

  }

  @media only screen and (max-width: 1600px) {
    .col-wide{width:75rem;} .col-boxed{width:68rem;}
  }
  @media only screen and (max-width: 1200px) {
    .col-wide,.col-boxed{width:100%;}
  }
  @media only screen and (max-width: 600px) {
    .imgLogo{
      width:auto;
    }

    #accountModal{
      inset: 0 !important;
      padding: 1rem !important;
      height: 100%;
    }

  }

`;
