import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList } from 'react-tabs';
import { logout } from '../../../state/user/user.service';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import 'react-spring-bottom-sheet/dist/style.css';
import * as Styled from '../styles';
import * as Menu from './styles';

import AccountForm from './../account';
import Orders from './../orders';
import Language from './../language';
import Wallet from './../wallet';
import AccountIcon from './../../../assets/images/user-account.png';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';
import { takeUntil } from 'rxjs/operators';
import { accountModalTrigger$ } from '../../../state/global-triggers/global-triggers.service';
import { Subject } from 'rxjs';

const AccountMenu = ({ tabs, type, user, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        login_button_label
        logout_button_label
        mobile_go_to_account_settings
        go_back
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        login_button_label
        logout_button_label
        mobile_go_to_account_settings
        go_back
      }
      strapiAccountModal_en: strapiAccountModal(locale: { eq: "en" }) {
        your_account_heading
        your_orders_heading
        your_transactions_heading
        your_wallet_heading
        language_preferences_heading
        your_email_subscriptions_heading
      }
      strapiAccountModal_zh: strapiAccountModal(locale: { eq: "zh" }) {
        your_account_heading
        your_orders_heading
        your_transactions_heading
        your_wallet_heading
        language_preferences_heading
        your_email_subscriptions_heading
      }
    }
  `);

  const translations = gql[`strapiNavigationMenu_${locale}`];
  const translationsTabs = gql[`strapiAccountModal_${locale}`];

  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [unMount$] = useState(new Subject());

  useEffect(() => {
    accountModalTrigger$.pipe(takeUntil(unMount$)).subscribe((d) => {
      openMenuSettings();
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  function openSelectedTab(value) {
    setShowContent(value);
  }

  function backToMenu() {
    setShowContent(false);
  }

  function dismiss() {
    setOpenAccountMenu(false);
    setOpenBottomSheet(false);
  }

  function openMenuSettings() {
    setOpenBottomSheet(false);
    setOpenAccountMenu(true);
  }

  function openMenuMob() {
    setOpenAccountMenu(true);
  }

  function getTabName(tab) {
    switch (tab.id) {
      case 1:
        return translationsTabs.your_account_heading;
      case 2:
        return translationsTabs.your_orders_heading;
      case 3:
        return translationsTabs.your_transactions_heading;
      case 4:
        return translationsTabs.language_preferences_heading;
      case 5:
        return translationsTabs.your_email_subscriptions_heading;
      case 6:
        return translationsTabs.your_wallet_heading;
    }
  }

  return (
    <>
      <Popover>
        {type === 'btn' && (
          <>
            <button onClick={() => openMenuMob()} className="mx-4 text-lg">
              {locale === 'en' ? 'Account' : '帐户'}
            </button>
          </>
        )}
        {type === 'mob' && (
          <>
            <button onClick={() => setOpenBottomSheet(true)} className="z-20 mt-2">
              <img src={AccountIcon} alt="Ubottle Account" />
            </button>

            <BottomSheet
              open={openBottomSheet}
              onDismiss={dismiss}
              snapPoints={({ minHeight }) => minHeight}
            >
              <Menu.BottomOpen>
                {!user?.id && (
                  <>
                    <Link to="/login">
                      <button>{translations.login_button_label}</button>
                    </Link>
                    <Link to="/login">
                      <button className="white">
                        {translations.mobile_go_to_account_settings}
                      </button>
                    </Link>
                  </>
                )}
                {user?.id && (
                  <>
                    <button onClick={() => logout()}>{translations.logout_button_label}</button>

                    <button onClick={() => openMenuSettings()}>
                      {translations.mobile_go_to_account_settings}
                    </button>
                  </>
                )}
              </Menu.BottomOpen>
            </BottomSheet>
          </>
        )}
        {type !== 'mob' && type !== 'btn' && (
          <div>
            <div className="fixed">
              <button onClick={() => setOpenAccountMenu(true)}>
                {translations.go_back}
              </button>
            </div>
          </div>
        )}
        <Transition.Root show={openAccountMenu} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 overflow-hidden z-10"
            onClose={setOpenAccountMenu}
          >
            <Menu.Wrapper>
              <Menu.WrapTransition>
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="-translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="-translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Menu.WrapContent>
                    <Menu.InnerContent>
                      <Menu.HeaderContent>
                        <div className="my-6">
                          <button
                            onClick={() => dismiss()}
                            className="border-2 border-black px-3 py-2"
                          >
                            {translations.go_back}
                          </button>
                        </div>
                        <Dialog.Title className="flex gap-2 align-middle text-xl font-medium text-gray-900">
                          <b>{locale === 'en' ? 'Account' : '帐户'}</b>
                        </Dialog.Title>
                        <p>{user?.email}</p>
                      </Menu.HeaderContent>
                      <Menu.BodyContent>
                        <TabList>
                          {tabs.map((tab) => (
                            <React.Fragment key={tab.id}>
                              <Tab>
                                <Styled.WrapList className="my-4">
                                  <span id={tab.icon} onClick={() => openSelectedTab(tab.icon)}>
                                    {getTabName(tab)}
                                  </span>
                                </Styled.WrapList>
                              </Tab>
                              <Transition.Root show={showContent === tab.icon} as={Fragment}>
                                <Dialog
                                  as="div"
                                  className="fixed inset-0 overflow-hidden z-10"
                                  onClose={setShowContent}
                                >
                                  <Menu.Wrapper>
                                    <Menu.WrapTransition>
                                      <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="-translate-x-full"
                                        enterTo="-translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="-translate-x-0"
                                        leaveTo="-translate-x-full"
                                      >
                                        <Menu.WrapContent>
                                          <Menu.InnerContent>
                                            <Menu.HeaderContent>
                                              <div className="my-6">
                                                <button
                                                  onClick={() => backToMenu()}
                                                  className="border-2 border-black px-3 py-2"
                                                >
                                                  {translations.go_back}
                                                </button>
                                              </div>
                                            </Menu.HeaderContent>
                                            <Menu.BodyContent>
                                              {tab.icon === 'user-account' ? (
                                                <AccountForm user={user} locale={locale} />
                                              ) : (
                                                ''
                                              )}
                                              {tab.icon === 'user-orders' ? (
                                                <Orders user={user} locale={locale} />
                                              ) : (
                                                ''
                                              )}
                                              {tab.icon === 'user-language' ? (
                                                <Language user={user} locale={locale} />
                                              ) : (
                                                ''
                                              )}
                                              {tab.icon === 'user-wallet' ? (
                                                <Wallet user={user} locale={locale} />
                                              ) : (
                                                ''
                                              )}
                                            </Menu.BodyContent>
                                          </Menu.InnerContent>
                                        </Menu.WrapContent>
                                      </Transition.Child>
                                    </Menu.WrapTransition>
                                  </Menu.Wrapper>
                                </Dialog>
                              </Transition.Root>
                            </React.Fragment>
                          ))}
                        </TabList>
                      </Menu.BodyContent>
                    </Menu.InnerContent>
                  </Menu.WrapContent>
                </Transition.Child>
              </Menu.WrapTransition>
            </Menu.Wrapper>
          </Dialog>
        </Transition.Root>
      </Popover>
    </>
  );
};

AccountMenu.propTypes = {
  tabs: PropTypes.any,
  type: PropTypes.any,
  user: PropTypes.any,
};

AccountMenu.defaultProps = {
  locale: 'en',
};

export default AccountMenu;
