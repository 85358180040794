import styled from "styled-components";
import tw from 'twin.macro';

export const WrapContent = styled.h2`
  ${tw`text-left`};
  h2{
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 10px;
  };
  button{
    ${tw`text-base py-2 px-6 text-white bg-black border border-black hover:text-black hover:bg-white`};
  }
`;

export const Hr = styled.div`
  height: 1px;
  border-top: 1px solid #D6D6D6;
  margin: 16px 0 24px;
`;

export const Flex = styled.div`
  ${tw`flex flex-col`};
`;

export const TableWrap = styled.div`
  height: 350px;
  overflow-y: scroll;
  table{
    ${tw`w-full divide-y divide-gray-200`}
  };
  thead{
    ${tw`bg-gray-50`}
  };
  tbody{
    
  };
  th{
    ${tw`px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
  };
  td{
    ${tw`px-2 py-2 whitespace-nowrap`}
    div{
      ${tw`text-xs text-gray-900`}
    };
    span{
      ${tw`px-2 inline-flex text-xs leading-5 font-medium rounded-full`}
    };
  }
`;