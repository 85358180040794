import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  height: 18px;
  ${tw`w-full flex justify-end text-right gap-2 -mb-4 my-auto`};
  button {
    width: auto;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    color: #9A9292;
    border:0;
  }
  select {
    width: auto;
    height:auto;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    color: #9A9292;
    padding:0;
    border:0;
    background: transparent;
    ${tw`hidden sm:block`};
  }
`;
