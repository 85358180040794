import { globalTriggersStore } from './global-triggers.store';
import { Observable } from "rxjs";
import { globalTriggersQuery } from "./global-triggers.query";
import { filter } from "rxjs/operators";

export const triggerAccountModal = (data): void => {
  globalTriggersStore.update({
    accountModalTrigger: data,
  });

  setTimeout(() => {
    globalTriggersStore.update({
      accountModalTrigger: null,
    });
  }, 200)
};

export const triggerPwaInstall = (data): void => {
  globalTriggersStore.update({
    pwaInstallTrigger: data,
  });

  setTimeout(() => {
    globalTriggersStore.update({
      pwaInstallTrigger: null,
    });
  }, 200)
};

export const accountModalTrigger$: Observable<boolean> = globalTriggersQuery.select('accountModalTrigger').pipe(
  filter((data) => !!data),
);

export const pwaInstallTrigger$: Observable<boolean> = globalTriggersQuery.select('pwaInstallTrigger').pipe(
  filter((data) => !!data),
);
