import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import 'react-spring-bottom-sheet/dist/style.css';

import { selectUser$ } from '../../../state/user/user.service';

import * as Styled from './styles';
import LogoSection from './../Logo';
import Language from './../Language';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { graphql, useStaticQuery } from "gatsby";
import { Link } from 'gatsby-plugin-react-i18next';
import { getAccessToken } from "../../../state/utils";
import DeliverTo from './DeliverTo'

import Button from './../../ui/ButtonStyle'
import { triggerPwaInstall } from "../../../state/global-triggers/global-triggers.service";

const MainNav = ({ page, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: {eq: "en"}) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: {eq: "zh"}) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
    }
  `);
  const translations = gql[`strapiNavigationMenu_${locale}`];

  const [unMount$] = useState(new Subject());
  const [user, setUser] = useState(null);

  useEffect(() => {
    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  return (
    <Styled.Wrap>
      <Styled.Desktop>
        <div className="flex">
          <LogoSection/>
          <Popover.Group as="nav" className="hidden w-full sm:flex space-x-10 my-auto main-nav">
            <Styled.MenuLink to="/shop" className={page === 'shop'? 'text-black' : ''}>{ translations.shop_menu }</Styled.MenuLink>
            <Styled.MenuLink to="/contact" className={page === 'contact'? 'text-black' : ''}>{ translations.contact_us_menu }</Styled.MenuLink>
            <Styled.MenuLink onClick={(e) => {e.preventDefault(); triggerPwaInstall(true)}} className={page === 'contact'? 'text-black' : ''}>{ translations.get_the_app }</Styled.MenuLink>
          </Popover.Group>
          <div className='w-full'>
            <div className='lang'>
              <Language user={user} locale={locale}/>
            </div>
            {!getAccessToken() && (
              <div className='hidden sm:flex flex-row-reverse gap-2.5 my-auto w-full text-right'>
                <Link to="/signup"><Button black>{ translations.signup_button_label }</Button></Link>
                <Link to="/login"><Button border>{ translations.login_button_label }</Button></Link>
              </div>
            )}
          </div>
        </div>
      </Styled.Desktop>

      <Styled.Mobile>
        <div className="flex">
          <LogoSection />
          <Styled.PostCode>
            <DeliverTo/>
          </Styled.PostCode>
          <div>
            <div className='lang'>
              <Language user={user} locale={locale}/>
            </div>
          </div>
        </div>
      </Styled.Mobile>
    </Styled.Wrap>
  );
};

MainNav.propTypes = {
  page: PropTypes.string.isRequired,
  locale: PropTypes.string,
};

MainNav.defaultProps = {
  locale: 'en',
};

export default MainNav;
