import styled from 'styled-components';


export const Wrap = styled.div`
  #pwaInstallModal {
    background: red;
  }
`;

export const Squares = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: #E2E2E2;
`;

export const LogoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Heading = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`;

export const Instructions = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Footer = styled.div`
  background: #F8F8F8;
  margin: -16px;
  margin-top: 50px;
  padding: 16px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 6px;
    }
  }
`;
