import styled from 'styled-components';
import tw from 'twin.macro';

export const Header = styled.header`
  width: 100%;
  ${tw`sm:bg-white sm:-mb-px`};
  .mobileHeaderHome{
    background: #232323;
    ${tw`sm:bg-white`};
  }
`;

export const Wrapper = styled.header`
  ${tw`items-center`};
`;
