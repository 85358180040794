import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../svg/close.svg';
import QrCode from '../../assets/images/qr-code-ubttole.png';
import * as Styled from './styles';
import { pwaInstallTrigger$ } from '../../state/global-triggers/global-triggers.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const PwaQrCode = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [unMount$] = useState(new Subject());

  useEffect(() => {
    pwaInstallTrigger$.pipe(takeUntil(unMount$)).subscribe(async () => {
      setIsOpen(true);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={true} id="pwaQrCodeModal">
      <img src={CloseIcon} alt={''} width={20} className={'float-right'} onClick={toggleModal} />
      <div>
        <Styled.Heading>Install App</Styled.Heading>
        <img style={{padding: '1rem'}} src={QrCode} alt="Qr Code" width={290}/>
        <Styled.Text>Scan the QR code to install our app</Styled.Text>
      </div>
    </Modal>
  );
};

export default PwaQrCode;
