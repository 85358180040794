import styled from "styled-components";
import tw from 'twin.macro';

export const WrapContent = styled.div`
  ${tw`text-left text-xssemi`};
  h2{
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 10px;
  };
  button{
    ${tw`text-base py-2 px-6 text-white bg-black border border-black hover:text-black hover:bg-white`};
  }
  p{
    letter-spacing: 0.03em;
    color: #737373;
    ${tw`text-xssemi`}
  }
  .defaultCard{
    background: #F8FBFF;
    ${tw`absolute top-0 right-4 rounded-b-md border-b border-r border-l border-gray-300 text-green-500`}
    padding: 7px 12px;
    margin-right: 48px;
    span{
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: #262626;
      margin: 1px 0 0;
      font-weight: 500;
    }
    svg{
      margin-right: 8px;
    }
  }
  .addCard{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #2F2F2F;
    background: #FFFFFF;
    margin: 0px 10px;
    padding: 8px 12px;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    box-shadow: 0px 2px 7px rgba(16, 68, 152, 0.04);
    border-radius: 2px;
  }
  .deleteCard{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #0963CD;
    margin: 2px 0px;
    background: #FFFFFF;
    border: 0;
    padding: 0;
  }
  .makeDefault{
    background: #0D090E;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }
  .radioStyle{
    ${tw`flex align-middle py-8 sm:py-4 relative`};
    img{
      margin: 0 16px;
    }
    span{
      b{
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: #000000;
      }
      p{
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: #737373;
      }
    }
  }
`;

export const Hr = styled.hr`
  ${tw`mt-4 pb-0`};
`;

export const WrapModal = styled.div`
  ${tw`text-center`};
  h3{
    ${tw`pt-6 text-base font-bold text-brown-semidark`};
  }
  p{
    ${tw`text-xssemi text-brown-lightmd`};
  }
  .whiteBgRed{

  }
`;

export const Flex = styled.div`
  ${tw`flex gap-2`};
`;

export const Button = styled.button`
  letter-spacing: 0.05em;
  ${tw`py-2 mt-4 text-base text-center`};
  ${({ outline }) => (outline ? tw`flex-1 bg-white border border-red-400 text-red-500` : tw``)};
  ${({ filled }) => (filled ? tw`flex-1 bg-red-400 border border-red-400 text-white` : tw``)};
  ${({ black }) => (black ? tw`flex px-4 bg-white border border-black text-black` : tw``)};
  ${({ blackFilled }) => (blackFilled ? tw`flex px-4 text-white bg-black border border-black` : tw``)};
`;

export const WrapNewCardModal = styled.div`
  ${tw`text-brown-darker`};
  h3{
    ${tw`text-base8 font-bold`};
  }
  hr{
    ${tw`my-3`};
  }
  b{
    ${tw`text-sm5 py-2`};
  }
  p{
    color: #292C2F;
    letter-spacing: inherit;
    ${tw`text-xs font-semibold`};
  }
  .wrapField{
    margin: 24px 0;
    p{
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #292C2F;
      ${tw`font-sans`};
    }
    #modalCardNumber,
    #modalCVV,
    #modalExpiryDate{
      margin-top: 4px;
      ${tw`focus:outline-none`}
    }
    #modalCVV, #modalExpiryDate{
      width: 123px;
    }
    input{
      margin-top: 4px;
      ${tw`focus:outline-none`}
    }
  }
  input{
    letter-spacing: initial;
    ${tw`w-full text-xssemi font-normal px-2 focus:outline-none`};
  }
`;

export const FlexReverse = styled.div`
  ${tw`flex flex-wrap content-start gap-2 float-right`};
  margin-top: 24px 0;
  .whiteBtn{
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: #0D090E;
    padding: 0 16px;
    background: #fff;
  }
  button{
    ${tw`text-base px-4 py-2`};
  }
`;
