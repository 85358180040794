import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby-plugin-react-i18next';

export const Logo = styled(Link)`
  ${tw`flex items-center pr-0`};
  margin-right: 2.5rem;
  @media (max-width: 990px) {
    margin-right: auto;
  }
`;

export const Text = styled.h1`
  ${tw`text-lg`};
`;

export const Image = styled.figure`
  ${tw`mr-0 sm:mr-3`};

  img {
    ${tw`border-0`};
  }
`;
